<template>
  <v-app>
    <v-app-bar app color="#F6F6F6" light elevation="0" height="100">
      <router-link
        :to="{ path: id && customer ? `/?id=${id}&customer=${customer}` : '/' }"
      >
        <!-- <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="64"
            :src="Logo"
            max-width="110"
            style="margin-left: 2rem"
          />
        </div> -->
      </router-link>

      <v-spacer></v-spacer>

      <router-link
        style="text-decoration: none"
        :to="{ path: '/', hash: '#que-cubre' }"
      >
        <v-btn text>
          <span class="mr-2">Qué cubre</span>
        </v-btn>
      </router-link>
      <router-link
        style="text-decoration: none"
        :to="{ path: '/', hash: '#como-usar' }"
      >
        <v-btn text>
          <span class="mr-2">Cómo usar</span>
        </v-btn>
      </router-link>
    </v-app-bar>

    <v-main>
      <router-view />
      <Footer :phoneNumber="phoneNumber" :cellPhone="cellPhone" />
    </v-main>
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import HitesLogo from "@/assets/hites_logo.svg";
import LaPolarLogo from "@/assets/la_polar_logo.svg";
import ABCDin from "@/assets/abcdin_logo.png";

export default {
  components: {
    Footer,
  },
  data: () => ({
    Logo: null,
    phoneNumber: null,
    cellPhone: null,
    id: null,
    customer: null,
  }),
  beforeMount() {
    const body = document.body;
    const favicon = document.getElementById("favicon");
    if (process.env.VUE_APP_SPONSOR === "abcdin") {
      body.classList.add("abcdin");
      favicon.href = "/icon.svg";
      this.Logo = ABCDin;
      this.phoneNumber = "800 380 911";
      this.cellPhone = "2 2826 8992";
    } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
      body.classList.add("la_polar");
      favicon.href = "/la_polar.ico";
      this.Logo = LaPolarLogo;
      this.phoneNumber = "800 399 999";
      this.cellPhone = "2 2826 8990";
    } else if (process.env.VUE_APP_SPONSOR === "hites") {
      body.classList.add("hites");
      favicon.href = "/hites.png";
      this.Logo = HitesLogo;
      this.phoneNumber = "800 200 559";
    }
    this.$router.onReady(() => {
      if (this.$route.query.id && this.$route.query.customer) {
        this.id = this.$route.query.id;
        this.customer = this.$route.query.customer;
      }
    });
  },
};
</script>