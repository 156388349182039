<template>
  <div class="home">
    <div style="background-color: #f6f6f6">
      <div
        style="
          border-bottom: 1px solid #8a8787;
          margin-right: 3rem;
          margin-left: 3rem;
        "
      ></div>
      <v-container>
        <v-row no-gutters>
          <v-col cols="7">
            <div class="intro">
              <h1 style="font-size: 3rem">
                Bienvenido a<br />
                <span :style="`color: ${color}; font-weight: 900;`">{{
                  serviceName
                }}</span>
              </h1>
              <p style="font-size: 1.25rem">
                ¡Todavía estás a tiempo! ¡Prolonga el tiempo de vida de tu
                producto!
              </p>
              <div>
                <v-btn
                  elevation="0"
                  :color="color"
                  dark
                  @click="() => goToGext()"
                  >Compra aquí tu garantía</v-btn
                >
              </div>
            </div>
          </v-col>
          <v-col style="display: flex; justify-content: center">
            <MainImage class="max-width-500" :color="color" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="que-cubre">
      <v-container>
        <v-row no-gutters>
          <div class="mt-4" style="width: 100%; text-align: center">
            <h1 style="font-weight: 700">
              ¿Qué cubre
              <span :style="`color: ${color};`">{{ serviceName }}</span
              >?
            </h1>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <CoverageCard
              :color="color"
              title="Prolonga el tiempo de garantía de tu producto"
              desc="Una vez terminada la garantía del fabricante."
            />
          </v-col>
          <v-col>
            <CoverageCard
              :color="color"
              title="Sin límites de reparaciones."
              :desc="null"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <CoverageCard
              :color="color"
              title="Reemplazo de producto"
              desc="En caso de que se extiendan el plazo de 10 días hábiles de reparación."
            />
          </v-col>
          <v-col>
            <CoverageCard
              :color="color"
              :title="null"
              desc="Incluye sin costo mano de obra, cambio de partes y piezas, y en el caso de productos grandes, los traslados y visitas a domicilio."
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <CoverageCard
              :color="color"
              title="Cobertura ante variaciones de voltaje."
              :desc="null"
            />
          </v-col>
          <v-col>
            <CoverageCard
              :color="color"
              title="Transferible a nuevos dueños si se vende o regala el producto."
              :desc="null"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <CoverageCard
              :color="color"
              title="Opera una vez expirada la garantía original."
              :desc="null"
            />
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-container>
    </div>
    <div style="background-color: #f6f6f6" id="como-usar">
      <v-container>
        <v-row class="mt-4" no-gutters>
          <v-col cols="6">
            <h1 style="font-weight: 700">
              ¿Cómo usar
              <span :style="`color: ${color};`">{{ serviceName }}</span
              >?
            </h1>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5">
            <div style="display: flex; justify-content: center">
              <HowTo :color="color" :serviceName="serviceName" />
            </div>
          </v-col>
          <v-col>
            <p style="font-size: 1.15rem">
              Si el producto para el que la contrataste falla, llama entre 09:00
              a 18:00 horas al
              <span :style="`color: ${color}; font-weight:700;`">{{
                phoneNumber
              }}</span>
              <span v-if="cellPhone">
                o a nuestro WhatsApp
                <span :style="`color: ${color}; font-weight:700;`">{{
                  cellPhone
                }}</span></span
              >. Podrás hacer uso de ella una vez termine el periodo de garantía
              del fabricante.
            </p>
            <p style="font-size: 1.15rem">
              Es importante que sepas que cuando existe intento de fraude, se
              pondrá término inmediato al contrato.
            </p>
            <p>
              <span
                :style="`color: ${color}; font-weight: 900; font-size: 1.25rem;`"
              >
                *
              </span>
              <span style="font-size: 0.85rem">
                La garantía extendida es comercializada y entregada por la
                compañía de servicios, SBI Seguros Spa
                <a href="http://www.sbiservicios.cl/">www.sbiservicios.cl</a>,
                por encargo de {{ sponsor }}.
              </span>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import MainImage from "@/components/MainImage.vue";
import CoverageCard from "@/components/CoverageCard.vue";
import HowTo from "@/components/HowTo.vue";
import ShieldService from "../services/shield.service";
import GoogleAnalytics from '@/utils/google-analytics.js';

export default {
  name: "Home",
  components: {
    MainImage,
    CoverageCard,
    HowTo,
  },
  data: () => ({
    color: "",
    sponsor: "",
    serviceName: "",
    productName: "Nombre de producto",
    phoneNumber: null,
    cellPhone: null,
    product: null,
  }),
  beforeMount() {
    if (this.$route.query.id && this.$route.query.customer) {
      if (process.env.VUE_APP_SPONSOR === "abcdin") {
        this.color = "#e0004d";
        this.serviceName = "ABCDin Garantía Extendida";
        this.sponsor = "ABCDin";
        this.phoneNumber = "800 380 911";
        this.cellPhone = "2 2826 8992";
      } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
        this.color = "#DA291C";
        this.serviceName = "La Polar Más Protección";
        this.sponsor = "La Polar";
        this.phoneNumber = "800 399 999";
        this.cellPhone = "2 2826 8990";
      } else if (process.env.VUE_APP_SPONSOR === "hites") {
        this.color = "#385E9D";
        this.serviceName = "Protección Hites";
        this.sponsor = "Hites";
        this.phoneNumber = "800 200 559";
      } else {
        this.color = "#eb345e";
        this.serviceName = "Garantía Extendida";
      }
      this.getProduct(this.$route.query.id, this.$route.query.customer);
    } else {
      this.$router.push({
        path: "404",
      });
    }
  },
  methods: {
    goToGext() {
      GoogleAnalytics.eventContratar();
      if (this.$route.query.id && this.$route.query.customer) {
        this.$router.push({
          path: "contrata",
          query: {
            id: this.$route.query.id,
            customer: this.$route.query.customer,
          },
        });
      }
    },
    async getProduct(id, customer) {
      const product = await ShieldService.getProduct(id, customer);
      this.product = product;
    },
  },
};
</script>

<style lang="scss" scoped>
.intro {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>