/* 
Se recupera la información cargada en la dataLayer en index.html
incluyendo la configuración de la global tag para vincular el
sitio con Google Analytics
*/
window.dataLayer = window.dataLayer || [];
function gtag(){
    window.dataLayer.push(arguments);
}

// Se crea una clase con los eventos a exportar
export default class GoogleAnalytics {
    static eventContratar(){
        if (process.env.VUE_APP_SPONSOR === "la_polar") {
            gtag('event', 'contratar', { 'label': 'contratar', 'value': 1 });
        }
        
    }

    static eventNextStep(step){
        if (process.env.VUE_APP_SPONSOR === "la_polar") {
            gtag('event', 'next_step', { 'label': 'next step', 'value': step });
        }
        
    }
}