import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

var title = "Garantía Extendida SBINS";
if (process.env.VUE_APP_SPONSOR === "abcdin") {
  title = "ABCDin Garantía Extendida";
} else if (process.env.VUE_APP_SPONSOR === "la_polar") {
  title = "La Polar Más Protección";
} else if (process.env.VUE_APP_SPONSOR === "hites") {
  title = "Protección Hites";
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: title,
    },
  },
  {
    path: "/contrata",
    name: "Contrata",
    component: () => import("../views/Gext.vue"),
    meta: {
      title: `Contrata tu garantía extendida | ${title}`,
    },
  },
  {
    path: "/success",
    name: "Exito",
    component: () => import("../views/Success.vue"),
    meta: {
      title: `${title}`,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
    meta: {
      title: `${title}`,
    },
  },
  {
    path: "/404",
    name: "Not Found",
    component: () => import("../views/404.vue"),
    meta: {
      title: `Error`,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 75 } };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
