import axios from "axios";
const { VUE_APP_SHIELD_URL } = process.env;
const { VUE_APP_SPONSOR } = process.env;

export default class ShieldService {
  static async getProduct(id, customer) {
    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/gext/product?sponsor=${VUE_APP_SPONSOR}&id=${id}&customer=${customer}`
    );
    return shieldRes.data.data;
  }

  static async getPlans(id, customer) {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/gext?sponsor=${VUE_APP_SPONSOR}&id=${id}&customer=${customer}`
      );
      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los precios",
      };
    }
  }

  static async create(validity, planId, id, customer) {
    const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/gext`, {
      planId: planId,
      validity: validity,
      id: id,
      customer: customer,
      sponsor: VUE_APP_SPONSOR,
    });
    return shieldRes.data.data;
  }
}
