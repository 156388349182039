<template>
  <div class="gext">
    <div
      style="
        border-bottom: 1px solid #8a8787;
        margin-right: 3rem;
        margin-left: 3rem;
      "
    ></div>
    <v-container>
      <v-row no-gutters style="min-height: 500px">
        <v-col cols="7">
          <div class="result">
            <h2 style="font-weight: 400">
              Página
              <span :style="`font-weight: 900;`">no encontrada</span><br />
            </h2>
          </div>
        </v-col>
        <v-col style="display: flex; justify-content: center"> </v-col>
      </v-row>
    </v-container>
    <div class="bg-image">
      <Error class="max-width-500" :color="color" />
    </div>
  </div>
</template>

<script>
import Error from "@/components/Error.vue";

export default {
  components: {
    Error,
  },
  data() {
    return {
      color: null,
      serviceName: null,
    };
  },
  beforeMount() {
    const body = document.body;
    if (process.env.VUE_APP_SPONSOR === "abcdin") {
      this.color = "#e0004d";
      this.serviceName = "ABCDin Garantía Extendida";
    } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
      this.color = "#DA291C";
      this.serviceName = "La Polar Más Protección";
    } else if (process.env.VUE_APP_SPONSOR === "hites") {
      body.classList.add("hites");
      this.color = "#385E9D";
      this.serviceName = "Protección Hites";
    } else {
      this.color = "#eb345e";
      this.serviceName = "Garantia Extendida";
    }
  },
};
</script>

<style lang="scss" scoped>
.result {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.bg-image {
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 0;
}

@media (max-width: 800px) {
  .bg-image {
    top: 250px;
  }
}

@media (max-width: 530px) {
  .bg-image {
    left: 0;
  }
}
</style>