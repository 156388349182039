<template>
  <div class="gext">
    <div style="background-color: #f6f6f6; padding-bottom: 2rem">
      <div style="
          border-bottom: 1px solid #8a8787;
          margin-right: 3rem;
          margin-left: 3rem;
        "></div>
      <v-container>
        <h1 style="font-weight: 400; text-align: center">
          Estás a solo
          <span :style="`color: ${color}; font-weight: 800;`">{{ stepsLeft }} paso{{ stepsLeft === 1 ? "" : "s"
            }}</span>
          de<br /><span style="font-weight: 800">extender la vida de tu producto</span>
        </h1>
        <v-card elevation="0">
          <v-card-text v-if="error">
            <h2>Ocurrio un error</h2>
            <p>
              Hubo un error al obtener los datos de tu producto. Verifica que la
              URL sea correcta e intenta nuevamente.
            </p>
          </v-card-text>
          <v-card-text v-else>
            <v-stepper elevation="0" v-model="step">
              <v-stepper-header>
                <v-stepper-step :color="color" :complete="step > 1" step="1">
                  Datos producto
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :color="color" :complete="step > 2" step="2">
                  Elige tu garantía
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :color="color" step="3">
                  Realizar pago
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content class="px-0" step="1">
                  <div class="text-center" v-if="loadingPlans">
                    <v-progress-circular :size="50" :color="color" indeterminate></v-progress-circular>
                  </div>
                  <div v-else>
                    <h3 class="mb-2" style="font-size: 0.8rem;">Estás cotizando una garantía para tu: <b>{{
                        product.description }}</b></h3>
                    <br>
                    <p>Fecha de compra: {{ date }}</p>
                    <p>Número de boleta: {{ product.barCode }}</p>
                    <p>Categoría: {{ product.areaDescription }}</p>
                    <p>SKU: {{ product.sku }}</p>
                  </div>
                  <v-btn :color="color" @click="() => nexStep()" dark>
                    Siguiente
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content class="px-0" step="2">
                  <h3 class="mb-4">Elige la garantía que más te acomoda</h3>
                  <div class="text-center" v-if="loadingPlans">
                    <v-progress-circular :size="50" :color="color" indeterminate></v-progress-circular>
                  </div>
                  <div class="gext-container" v-else>
                    <div v-for="option in gextOptions" :key="option.id" :class="`gext-selector ${
                      gextSelect && option.id === gextSelect.id
                        ? 'active'
                        : ''
                    }`" :style="`border: 1px solid ${color} ;${
                      gextSelect && option.id === gextSelect.id
                      ? `background-color: ${color}; min-width: 150px; min-height: 75px;`
                      : 'min-width: 150px; min-height: 75px;'
                    }`" @click="selectGext(option)">
                      <div type="radio" :id="option.id" :value="option" />
                      <label :for="option.id">
                        <div class="title">{{ option.name }}</div>
                        <div class="desc">{{ option.description }}</div>
                      </label>
                    </div>
                  </div>
                  <div v-if="gextSelect" class="price-container">
                    <p>Llévalo por</p>
                    <div class="price">
                      {{ formatPrice(gextSelect.price) }}
                    </div>
                  </div>
                  <h3 class="mb-4">Selecciona el método de pago</h3>
                  <div class="payment-container">
                    <div v-for="option in paymentOptions" :key="option.id" :class="`payment-selector ${
                      paymentMethod && option.id === paymentMethod.id
                        ? 'active'
                        : ''
                    }`">
                      <input type="radio" :id="option.id" :value="option" v-model="paymentMethod" />
                      <label :for="option.id">
                        <img :alt="option.name" :src="option.image" class="payment-icon" />
                      </label>
                    </div>
                  </div>
                  <input type="checkbox" id="checkbox" v-model="hasAccept" value="Acepto términos y Condiciones">
                  <label for="checkbox"> Acepto términos y Condiciones</label>
                  &thinsp;
                  <b><a :href="'/' + pdf" target='_blank'>Ver aquí</a></b>

                  <p></p>
                  <p></p>

                  <div style="text-align: center;">
                    <v-btn :disabled="!paymentMethod || !gextSelect || !hasAccept" :color="color"
                      @click="() => goToStep2()" dark>
                      Aceptar
                    </v-btn>
                  </div>

                </v-stepper-content>

                <v-stepper-content v-if="gextSelect && paymentMethod" class="px-0" step="3">
                  <div class="text-center" v-if="loading">

                    <v-progress-circular :size="50" :color="color" indeterminate></v-progress-circular><br><br>
                    Cargando . . .
                  </div>
                  <div v-else>
                    <h3 class="mb-4">
                      Garantía extendida por {{ gextSelect.description }}
                    </h3>
                    <p>Valor: {{ gextSelect.name }}</p>
                    <p>
                      Tu producto: {{ product.itemDescription }}
                      {{ product.brandName }} {{ product.model }}
                    </p>
                    <p>Método de pago: {{ paymentMethod.name }}</p>
                    <img :alt="paymentMethod.name" :src="paymentMethod.image"
                      style="width: 200px; margin-bottom: 2rem" />

                    <form v-if="paymentMethod.id === 'webpay'"
                      action="https://webpay3gint.transbank.cl/webpayserver/initTransaction" method="POST"
                      ref="form_payment">
                      <input type="hidden" name="token_ws" :value="paymentToken" />
                      <v-btn :color="color" type="submit" value="Pagar" dark
                        @click="(event)=>{event.preventDefault(); GoogleAnalytics.eventNextStep(this.step); this.$refs.form_payment.submit();}">
                        Ir a pagar
                      </v-btn>
                    </form>
                    <!-- <div v-if="paymentMethod.id === 'hitespay'">
                    <v-btn :color="color" :href="hitesPayUrl" dark>
                      Ir a pagar
                    </v-btn>
                  </div> -->
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </div>
</template>

<script>
import webPayLogo from "@/assets/webpay.png";
// import hitesPayLogo from "@/assets/hitespay.png";
import ShieldService from "../services/shield.service";

import GoogleAnalytics from '../utils/google-analytics.js'

export default {
  data() {
    return {
      step: 1,
      stepsLeft: 3,
      product: null,
      color: process.env.VUE_APP_COLOR,
      paymentToken: null,
      hitesPayUrl: null,
      pdf: null,
      hasAccept: null,
      paymentOptions: [
        {
          id: "webpay",
          name: "WebPay Plus",
          image: webPayLogo,
        },
      ],
      paymentMethod: null,
      gextOptions: [],
      gextSelect: null,
      loading:null,
      loadingProduct: true,
      loadingPlans: true,
      plans: [],
      planId: null,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      productId: null,
      customer: null,
      error: null,
      date: null,
      GoogleAnalytics: GoogleAnalytics
    };
  },
  beforeMount() {
    if (process.env.VUE_APP_SPONSOR === "abcdin") {
      this.pdf="certificado_test.pdf";
    } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
      this.pdf="certificado_la_polar.pdf";
    } else if (process.env.VUE_APP_SPONSOR === "hites") {
      this.pdf="certificado_test.pdf";
    } else {
      this.pdf="certificado_test.pdf";
    }
    if (this.$route.query.id && this.$route.query.customer) {
      this.productId = this.$route.query.id;
      this.customer = this.$route.query.customer;
      const body = document.body;
      if (process.env.VUE_APP_SPONSOR === "abcdin") {
        this.color = "#e0004d";
        this.serviceName = "ABCDin Garantía Extendida";
      } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
        this.color = "#DA291C";
        this.serviceName = "La Polar Más Protección";
      } else if (process.env.VUE_APP_SPONSOR === "hites") {
        body.classList.add("hites");
        this.color = "#385E9D";
        this.serviceName = "Protección Hites";
       /*  this.paymentOptions.push({
          id: "hitespay",
          name: "HitesPay",
          image: hitesPayLogo,
        }); */
      } else {
        this.color = "#eb345e";
        this.serviceName = "Garantía Extendida";
      }
      this.getProduct(this.$route.query.id, this.$route.query.customer);
    } else {
      this.$router.push({
        path: "404",
      });
    }
  },
  methods: {
    nexStep(){
      this.step = this.step + 1
      this.stepsLeft = this.stepsLeft - 1
    },
    async getProduct(id, customer) {
      try {
        const product = await ShieldService.getProduct(id, customer);
        this.product = product;
        var fecha = new Date()
        var newFecha = `${(fecha.getMonth() + 1).toString().padStart(2, '0')}/${fecha.getDate().toString().padStart(2, '0')}/${fecha.getFullYear().toString().padStart(4, '0')} ${fecha.getHours().toString().padStart(2, '0')}:${fecha.getMinutes().toString().padStart(2, '0')}:${fecha.getSeconds().toString().padStart(2, '0')}`
        this.date = newFecha
        this.loadingProduct = false;
        await this.getPlans();
      } catch (error) {
        this.error = true;
      }
    },
    async getPlans() {
      this.loadingPlans = true;
      const plans = await ShieldService.getPlans(
        this.$route.query.id,
        this.$route.query.customer
      );
      this.plans = plans;
      if (plans.length > 0) {
        this.planId = plans[0]._id;
        this.gextOptions = plans[0].values.map((el, index) => {
          return {
            id: index,
            description: `${el.validity.quantity} mes${
              el.validity.quantity != 1 ? "es" : ""
            }`,
            name: this.formatPrice(el.priceCLP),
            price: el.priceCLP,
            validity: el.validity,
          };
        });
        this.loadingPlans = false;
      }
    },
    selectGext(option) {
      this.gextSelect = option;
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async goToStep2() {
      //GoogleAnalytics.eventNextStep(this.step)
      this.GoogleAnalytics.eventNextStep(this.step)
      this.stepsLeft = this.stepsLeft - 1
      this.step++;
      this.loading= true;
      if (this.paymentMethod && this.gextSelect) {
        const res = await ShieldService.create(
          this.gextSelect.validity,
          this.planId,
          this.productId,
          this.customer
        );
        this.paymentToken = res.token;
        this.loading=false;
        
      }
    },
  
  },
};
</script>

<style lang="scss" scoped>
.check{
  display: flex;
  justify-content: center;
}
.v-stepper__header {
  box-shadow: none;
}

.container {
  max-width: 900px !important;
}
.v-stepper__content {
  display: block;
  max-width: 500px !important;
  margin-left: auto;
  margin-right: auto;
}
.price-container {
  text-align: center;

  .price {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 3rem;
  }
}
.gext-container {
  display: block;
  position: relative;
  text-align: center;

  .gext-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.25;
    width: 120px;
    height: 65px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    .title,
    .desc {
      cursor: pointer;
    }

    &.active {
      color: white;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }
  }
}

.payment-container {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;

  .payment-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }
    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .payment-icon {
      height: 65px;
      display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;

    // border: 1px solid #f5f5f5;
    cursor: pointer;
    }
  }
}
</style>