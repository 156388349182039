<template>
  <div class="gext">
    <div style="
        border-bottom: 1px solid #8a8787;
        margin-right: 3rem;
        margin-left: 3rem;
      "></div>
    <v-container>
      <v-row no-gutters>
        <v-col cols="7">
          <div class="result">
            <h2 style="font-weight: 400">
              Tu pago ha sido procesado<br />
              <span :style="`font-weight: 900;`">correctamente</span>
            </h2>
            <h2 style="font-weight: 400">Descarga tu Certificado <b><a :href="'/' + pdf" target='_blank'>AQUÍ</a></b>
            </h2>
            <h3>En unos momentos recibirás el Certificado de tu garantía</h3>
            <h3>en tu correo</h3>
            <div>
              <v-btn elevation="0" color="transparent" light @click="() => goToHome()">Volver a la página principal
                <v-icon right dark> mdi-arrow-right </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col style="display: flex; justify-content: center">
          <Success class="max-width-500" :color="color" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Success from "@/components/Success.vue";

export default {
  components: {
    Success,
  },
  data() {
    return {
      color: null,
      serviceName: null,
      pdf: null,
    };
  },
  beforeMount() {
    const body = document.body;
    if (process.env.VUE_APP_SPONSOR === "abcdin") {
      this.color = "#e0004d";
      this.serviceName = "ABCDin Garantía Extendida";
      this.pdf="certificado_test.pdf";
    } else if (process.env.VUE_APP_SPONSOR === "la_polar") {
      this.color = "#DA291C";
      this.serviceName = "La Polar Más Protección";
      this.pdf="certificado_la_polar.pdf";
    } else if (process.env.VUE_APP_SPONSOR === "hites") {
      body.classList.add("hites");
      this.color = "#385E9D";
      this.serviceName = "Protección Hites";
      this.pdf="certificado_test.pdf";
    } else {
      this.color = "#eb345e";
      this.serviceName = "Garantia Extendida";
      this.pdf="certificado_test.pdf";
    }
  },
  methods: {
    goToHome() {
      this.$router.push({ path: "../" });
    },
    goToGext() {
      this.$router.push({ path: "../contrata" });
    },
  },
};
</script>

<style lang="scss" scoped>
.result {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.bg-image {
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 0;
}

@media (max-width: 800px) {
  .bg-image {
    top: 250px;
  }
}

@media (max-width: 530px) {
  .bg-image {
    left: 0;
  }
}
</style>