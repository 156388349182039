<template>
  <v-container style="margin-top: 4rem; margin-bottom: 5rem">
    <v-row>
      <v-col cols="4">
        <h3 class="footer-header">Contacto</h3>
        <p v-if="phoneNumber" class="footer-text">{{ phoneNumber }}</p>
        <p v-if="cellPhone" class="footer-text">
          Desde celulares al {{ cellPhone }}
        </p>
      </v-col>
      <v-col cols="4">
        <h3 class="footer-header">Información</h3>
        <p class="footer-text">Dirección</p>
        <p class="footer-text">Correo electrónico</p>
      </v-col>
      <v-col cols="4"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Footer",
  props: ["phoneNumber", "cellPhone"],
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-header {
  color: #8a8787;
  border-bottom: 1px solid #8a8787;
  padding-bottom: 0.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.footer-text {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}
@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .col {
    margin-bottom: 1rem;
    max-width: none !important;
  }
}
</style>